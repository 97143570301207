import React  from 'react';
import Paper  from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import * as config from '../../config'
import FreeScrollBar from 'react-free-scrollbar';
import {Cookie } from '../../static/typescript/common'
// import { UAParser } from '../../static/typescript/ua'
interface Props  {
  search: {
    searchEngine: number;
  };
  menu: any;
  snackbar: any;
  changeFavoriteList:  Function;
  toggleMenu:          Function;
  toggleFavorite:      Function;
  toggleSearchEngine:  Function;
  toggleSnackbar:      Function;
  toggleSnackbarText:  Function;
  toggleSnackbarColor: Function;
}
interface State  {
  open: boolean;
  timer?: any;
  safari?:string;
}

export default class Menu extends React.Component<Props, State> {
  constructor(props:Props){
    super(props)
    this.state = {
        open: false,
        timer: false,
        // safari:'',
    }
  }
  componentDidMount(){
    // 设置 弹出框高度
    let menu = document.getElementById('menu');
    if(menu){
      menu.style.height = window.innerHeight - 130 + 'px';
    }
  }
  aLinkStopJump(e: any){
    if(this.props.menu.toggle_favorite){
      e.preventDefault()
    }
  }
  changeAddFavoriteValue (){
    this.props.toggleFavorite()
  }
  snackbar(color:number , text:string){
    if(this.state.timer){
      clearTimeout(this.state.timer);
    }
    var timer = setTimeout(()=>{
      this.props.toggleSnackbar(false);
    },1500);
    this.setState({timer});
    this.props.toggleSnackbar(true);
    this.props.toggleSnackbarText(text);
    this.props.toggleSnackbarColor(color);
  }
  addFavorite(e: any) {
    if(!e.currentTarget.classList.contains('icon-favorite')){
      return;
    }
    const selfIndex   = e.currentTarget.dataset.key as number;
    const parentIndex = e.currentTarget.parentNode.parentNode.parentNode.dataset.key as number;

    const favoriteNode = config.LINKINFO[Object.values(config.MENU)[+parentIndex].content[+selfIndex]];
    if(typeof favoriteNode ==='object'){
      let favoriteNodeString = JSON.stringify(favoriteNode);
      let favorite = Cookie.get('favorite');
      if(!favorite){
        Cookie.set('favorite', `[${favoriteNodeString}]`, 356);
        this.snackbar(4 , `添加 <span style ="color:#FFA000">${favoriteNode.name}</span> 到主页导航成功！`);
        this.props.changeFavoriteList([favoriteNode]);
        return ;
      }

      let favoriteList = JSON.parse(favorite)
      if(favoriteList.length >= 12){
        this.snackbar(1 , '主页导航不能超过12个！');
        return ;
      }

      if(favoriteList.some((list: any)=>{
        return list.name === favoriteNode.name
      })){
        return this.snackbar(2 , `<span style ="color:#4285F4">${favoriteNode.name}</span>已存在 请勿重复添加主页导航！`);
      }

      favoriteList.push(favoriteNode);
      Cookie.set('favorite',JSON.stringify(favoriteList), 356);
      this.props.changeFavoriteList(favoriteList);
      this.snackbar(4 , `添加 <span style ="color:#FFA000">${favoriteNode.name}</span> 到主页导航成功！`);
    }
  }
  render(){
    return (
      <Paper id = 'menu'
        className = {`container ${this.state.safari} animated first-mount-hidden ${this.props.menu.toggle_menu ? 'fadeInUp' :' fadeOutDown'}`}
        style =  {
          this.props.menu.toggle_menu ?
          {
            bottom:'72px',
            padding: '32px 0',
          }:
          {
            padding: '0',
          }}
      >
        <FreeScrollBar
          autohide = {true}
        >
          <div className='row' onClick={(e) => this.aLinkStopJump(e)}>
            {Object.values(config.MENU).map((val, key) => {
                // console.log(val.content);
                return (<div className="menu-module col-lg-4 col-md-6 col-sm-6 col-12" data-key = {key} key = {key}>
                  <Button color="primary"  className='menu-title col-12'>{val.title}</Button>
                  {
                    val.content.map((sitekey: string, key: number) => {
                      const site = config.LINKINFO[sitekey];
                      // console.log(site);
                      return (<Button href={site.url} key={key} target='_blank' className = 'menu-list col-3 col-sm-4'>
                        <div
                          className= {
                            this.props.menu.toggle_favorite ? "icon-favorite animated rotateIn":
                            "icon-favorite animated rotateOut"
                          }
                          data-key = {key}
                          onClick={(e)=> this.addFavorite(e)}
                        >
                          <svg className="icon" aria-hidden="true">
                            <use href='#icon-add'></use>
                          </svg>
                        </div>
                        <div className='menu-icon'>
                          <svg className="icon" aria-hidden="true">
                            <use href={site.icon}></use>
                          </svg>
                        </div>
                        <div className="menu-icon-title">
                          {site.name}
                        </div>
                      </Button>)
                    })
                  }
                </div>)
              })}
            </div>
        </FreeScrollBar>
        <span
          onClick = {()=> this.changeAddFavoriteValue()}
          className = {this.props.menu.toggle_favorite ? 'addFavorite animated rotateIn':'addFavorite animated rollIn'}
        >
          <svg className="icon" aria-hidden="true">
            <use href={this.props.menu.toggle_favorite ? '#icon-guanbi1':'#icon-gongju'}></use>
          </svg>
        </span>
      </Paper>
    )
  }
}