import { Cookie } from '../../../static/typescript/common'

const HISTORY_COUNT = 10
const CHANGE_SEARCH_HISTORY:string = 'change_search_history'
const HIDDEN_SEARCH_HISTORY:string = 'historyState'
const QUERY_KEYWORD_LIST:string    = 'keywordList'
const TOGGLE_KEYWORD_LIST:string   = 'keywordState'
const TOGGLE_SEARCH_ENGINE:string  = 'searchEngine'
const FAVORITE_LIST:string         = 'favoriteList'
const SEARCH_HISTROY:string        = 'historyList'

let SEARCH_HISTORY_LIST: any       = {
	historyList: [],
	historyState: false, //用于页面flag
	keywordState: false, //用于页面flag
	searchEngine: 0,
	keywordList:  [],
	favoriteList: [],
}

export default function (state = SEARCH_HISTORY_LIST , action:{type: string, payload: any})  {
	switch(action.type) {
		case CHANGE_SEARCH_HISTORY :
			if(!state.historyList.includes(action.payload)){
				state.historyList.unshift(action.payload)
				//只记录十条历史记录
				if(state.historyList.length > HISTORY_COUNT) state.historyList.pop()
				setSearchHistoryForCookie(state.historyList)
				return Object.assign({}, state)
			}
			return state
		case HIDDEN_SEARCH_HISTORY:
		case TOGGLE_KEYWORD_LIST  :
		case QUERY_KEYWORD_LIST   :
		case FAVORITE_LIST        :
		case SEARCH_HISTROY       :
		case TOGGLE_SEARCH_ENGINE :
			state[action.type] = action.payload
			return Object.assign({}, state)
		default:
			return state
	}
}

//默认action 动作
function defaultAction(type:string, payload:any){
	return {
		type,
		payload
	}
}

export function getSearchHistoryForCookie(keyField:string):string[]{
	let jsonString = Cookie.get(keyField)
	let ListArr:string[] = [];
	if(jsonString){
		ListArr   = JSON.parse(jsonString);
	}
	return ListArr
}
export function setSearchHistoryForCookie(history:string[]): void{
	Cookie.set(CHANGE_SEARCH_HISTORY, JSON.stringify(history) , 7)
}

export function ObtainExpiresTime(days: number){
	const expires = new Date()
	const dayms   = 24*60*60*1000
	expires.setTime(expires.getTime() + days * dayms)
	return expires
}
// 更改搜索记录 change_search_history
export function changeSearchHistory(payload:string) {
	return defaultAction(CHANGE_SEARCH_HISTORY, payload)
}
// 隐藏搜索历史
export function hiddenSearchHistory(payload:boolean){
	return defaultAction(HIDDEN_SEARCH_HISTORY, payload)
}

export function toggleKeywordList(payload:boolean){
	return defaultAction(TOGGLE_KEYWORD_LIST, payload)
}

//获取关键字列表
export function queryKeywordList(payload:string[]){
	return defaultAction(QUERY_KEYWORD_LIST, payload)
}
//切换搜索引擎
export function toggleSearchEngine(payload:string){
	return defaultAction(TOGGLE_SEARCH_ENGINE, payload)
}
//切换搜索引擎
export function changeFavoriteList(payload:[]){
	return defaultAction(FAVORITE_LIST, payload)
}
//切换搜索引擎
export function InitSearchHistory(payload:[]){
	return defaultAction(SEARCH_HISTROY, payload)
}