import React      from 'react';
import CloseIcon  from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar   from '@material-ui/core/Snackbar';

import './snackbar.css'
interface Props {
  snackbar: any;
  toggleSnackbar:      Function;
  toggleSnackbarColor: Function;
}

export default class SnackbarInfo extends React.Component<Props>{
  closeSnackbar(){
    this.props.toggleSnackbar(false);
  }
  render(){
      let backgroundColor = this.props.snackbar.toggle_snackbar_color === 1 ? {backgroundColor:'#D32F2F',color:'#EEE'}:
                            this.props.snackbar.toggle_snackbar_color === 2 ? {backgroundColor:'#FFA000',color:'#333'}:
                            this.props.snackbar.toggle_snackbar_color === 3 ? {backgroundColor:'#90CAF9',color:'#333'}:
                            this.props.snackbar.toggle_snackbar_color === 4 ? {backgroundColor:'#43A047',color:'#EEE'}:
                            this.props.snackbar.toggle_snackbar_color === 5 ? {backgroundColor:'#333333',color:'#EEE'}:
                            this.props.snackbar.toggle_snackbar_color === 6 ? {backgroundColor:'#EEEEEE',color:'#333'}:
                            {backgroundColor:'#EEEEEE',color:'#333'};
      return(
            <Snackbar id = 'snackbar'
              anchorOrigin={{ vertical:'top', horizontal: 'center'}}
              key={`top,center`}
              open={this.props.snackbar.toggle_snackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id" dangerouslySetInnerHTML={{ __html:this.props.snackbar.toggle_snackbar_text}}></span>}
              action={[
                <IconButton key="close" aria-label="close" color="inherit"
                 onClick={()=>this.closeSnackbar()}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
              style = {backgroundColor}
            />
      );
  }
}