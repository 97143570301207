/**
 * jsonp 实现
 * @param params Object
 * {
 *     url: string;
 *     cb: string;
 *     data?: Record<string, unknown>;
 *     success?: (r: unknown) => void;
 *     error?:(e: unknown) => void;
 * }
 * @returns unknown
 */
export const jsonp = (params: {
    url: string;
    cb: string;
    data?: Record<string, unknown>;
    success?: (r: unknown) => void;
    error?:(e: unknown) => void;
}): Promise<unknown> => {
    if (typeof window === 'undefined' || !window.document)
        throw new Error('jsonp requires a window with a document');

    const { url, cb, data, success, error } = params;

    if (!url || !cb)
        throw new Error('No url parameter found!');

    // 固定长度随机数
    const random_str = (len: number,
        type: 'number' | 'char' | 'mixed' = 'number'): string => {
        // 0 ~ 9 48 ~ 57
        // A ~ Z 65 ~ 90
        // a ~ z 97 ~ 122
        const char_number = () => {
            const mr = Math.random();
            const char_base = type === 'char' ? (mr > 0.5 ? 65 : 97) :
                type === 'mixed' ? (mr > 0.67 ? 65 : mr > 0.33 ? 97 : 48) : 48;
            const mr_base = ['char', 'mixed'].includes(type) &&
                char_base > 57 ? 26 : 10;
            return Math.floor(Math.random() * mr_base) + char_base;
        };

        const arr_len = Array(len).fill(0);

        return arr_len.map(() =>
            String.fromCharCode(char_number())).join('');
    };

    return new Promise((res, rej) => {

        // accept func
        const jsonp_cb = `jsonp_${random_str(Math
            .ceil(Math.random() * 10) + 10, 'mixed')}`;

        // create script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        // query search content
        const search = !data ? '' : Object.keys(data).map(k =>
            encodeURIComponent(k) + '=' + encodeURIComponent(String(data[k]))).join('&');

        // jsonp request url
        script.src = url + `${url.indexOf('?') !== -1 ? '&' :
            '?'}${cb}=${jsonp_cb}&${search}`;
        // query HeadElement
        const head = document.querySelector('head') as HTMLHeadElement ;

        // script 加载失败
        script.onerror = (e) => {
            error && error(e);
            rej(e);
            // remove script dom
            head.removeChild(script);
            // delete window method jsonp_cb
            delete window[jsonp_cb as any];
        };

        // jsonp 请求成功
        Object.defineProperty(window, jsonp_cb, {
            value: (json_data: unknown) => {
                console.log(json_data);
                success && success(json_data);
                // return data
                res(json_data);
                // remove script dom
                head.removeChild(script);
                // delete window method jsonp_cb
                delete window[jsonp_cb as any];
            },
            configurable: true
        });
        head.appendChild(script);
    });
};
