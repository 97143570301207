import React    from 'react';
import Search   from './component/search/search'
import Nav      from './component/nav/nav'
import Snackbar from './component/snackbar/snackbar'
// import Setting  from './component/setting/setting'
//redux
import * as SearchRudex   from './redux/reducer/search/search'
import * as MenuRudex     from './redux/reducer/menu'
import * as SnackbarRudex from './redux/reducer/snackbar'
import {Cookie}           from './static/typescript/common'

import './App.css';
import './component/search/search.css'
import './component/nav/nav.css'
//完结时将所有样式表合并                        TODO:
//可以做静态样式引入  在构造函数里可以做主题切换  TODO:
// require('./theme.css')
//切换主题
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider }  from '@material-ui/styles';
// import { purple } from '@material-ui/core/colors';//获取颜色 purple[500] = #f60
const  { connect } = require('react-redux')

interface Props  {
  menu:     any;
  // socket:   any;
  search:   any;
  snackbar: any;
  changeFavoriteList:  Function;
  changeSearchHistory: Function;
  hiddenSearchHistory: Function;
  InitSearchHistory:   Function;
  queryKeywordList:    Function;
  toggleMenu:          Function;
  toggleFavorite:      Function;
  toggleSnackbar:      Function;
  toggleKeywordList:   Function;
  toggleSearchEngine:  Function;
  toggleSnackbarColor: Function;
  toggleSnackbarText:  Function;
}
interface State  {
  theme?: any;
}
class App extends React.Component<Props, State> {
  constructor(props:any){
    super(props)
    this.state = {
      theme: createMuiTheme({
        palette: {
          // type:  'dark',
          // primary: {
            // light: "#f60",
            // main: "#FE163E",
            // dark: "#f60",
            // contrastText: "#f60",
          // }
        },
      }),
    }
    //初始化数据 redux
    const SEARCH_HISTORY = this.getListCookie('change_search_history')
    const FAVORITE_LIST  = this.getListCookie('favorite')
    this.props.InitSearchHistory(SEARCH_HISTORY)
    this.props.changeFavoriteList(FAVORITE_LIST)
  }
  componentDidMount(){
    setTimeout(()=>{
      //去除一次性样式 first-mount-hidden
      const first_mount_hidden = document.getElementsByClassName('first-mount-hidden');
      if(first_mount_hidden.length > 0){
        let childNode: any;
        for(let i = 0; i < first_mount_hidden.length; i++){
          childNode = first_mount_hidden[i];
          childNode.style.visibility = 'unset';
        }
      }
    },1100);
  }
  getListCookie(keyField:string):string[]{
    let jsonString = Cookie.get(keyField)
    let ListArr:string[] = [];
    if(jsonString){
      ListArr   = JSON.parse(jsonString);
    }
    return ListArr
  }
  render(){
    return (
      <div id = 'app'>
        <ThemeProvider theme = {this.state.theme}>
          <Search   {...this.props} />
          <Nav      {...this.props} />
          <Snackbar {...this.props} />
          {/* <Setting  {...this.props} /> */}
        </ThemeProvider>
      </div>
    );
  }
}

export default connect(
  (state:any) => ({
    // socket:    state.Socket,
    search:    state.Search,
    menu:      state.Menu,
    snackbar:  state.Snackbar,
  }),
  {
    ...SearchRudex,
    ...MenuRudex,
    ...SnackbarRudex
  }
)(App)