import { combineReducers } from 'redux'
import Search              from './search/search'
import Menu                from './menu'
// import Socket              from './socket'
import Snackbar            from './snackbar'

export default combineReducers({
  Search,
  Menu,
  // Socket,
  Snackbar,
});