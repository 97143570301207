const menuState:any = {
	toggle_menu: false,
	toggle_favorite: false,
};
const TOGGLE_MENU     = 'toggle_menu'
const TOGGLE_FAVORITE = 'toggle_favorite'

export default function (state = menuState , action:{type:string, payload:any})  {
	switch(action.type) {
		case TOGGLE_FAVORITE:
		case TOGGLE_MENU :
			state[action.type] =  action.payload === false ? false : !state[action.type]
			return Object.assign({}, state)
		default:
			return state;
	}
}

//默认action 动作
function defaultAction(type:string, payload:any){
	return {
		type,
		payload
	}
}

export function toggleMenu(payload:boolean) {
	return defaultAction(TOGGLE_MENU, payload)
}
export function toggleFavorite(payload:boolean) {
	return defaultAction(TOGGLE_FAVORITE, payload)
}