export const DOMAIN = 'http://localhost:8080';
// export const DOMAIN = 'https://iyou.ink'
export const SEARCH_TYPE = [
    { name: '百度', url: 'https://www.baidu.com/s?wd=', engine: 'baidu', icon: '#icon-baidu', index: 0 }
    , { name: '必应', url: 'https://www4.bing.com/search?q=', engine: 'biying', icon: '#icon-biying1', index: 1 }
    , { name: '谷歌', url: 'https://www.google.com/search?q=', engine: 'google', icon: '#icon-gugesousuo', index: 2 }
    , { name: '360', url: 'https://www.so.com/s?q=', engine: '360', icon: '#icon-icon-test3', index: 3 }
    , { name: 'Yandex', url: 'https://yandex.com/search/?text=', engine: 'yandex', icon: '#icon-yandex', index: 4 }
]
export const LINKINFO: any = {
    '1': { name: '果壳', icon: '#icon-gkw', index: 0, url: 'https://www.guokr.com/' },
    '2': { name: '少数派', icon: '#icon-ssp', index: 1, url: 'https://sspai.com/' },
    '3': { name: '36Kr', icon: '#icon-kr', index: 2, url: 'https://36kr.com/' },
    '4': { name: '路透社', icon: '#icon-lts1', index: 3, url: 'https://cn.reuters.com/' },
    '5': { name: 'BBC', icon: '#icon-bbc', index: 4, url: 'https://www.bbc.com/zhongwen/simp' },
    '6': { name: 'CNN', icon: '#icon-cnn', index: 5, url: 'https://www.cnn.com/' },
    '7': { name: '纽约时报', icon: '#icon-nysb', index: 6, url: 'https://cn.nytimes.com' },
    '8': { name: '网易新闻', icon: '#icon-wangyi', index: 7, url: 'https://www.163.com' },
    '9': { name: '今日头条', icon: '#icon-jrtt', index: 8, url: 'https://www.toutiao.com' },
    '10': { name: '中关村', icon: '#icon-zhongguancunzaixian1', index: 9, url: 'http://www.zol.com.cn' },
    '11': { name: '搜狐新闻', icon: '#icon-souhu', index: 10, url: 'http://www.sohu.com/' },
    '12': { name: '腾讯新闻', icon: '#icon-tengxunxinwen', index: 11, url: 'https://news.qq.com/' },
    '13': { name: '哔哩哔哩', icon: '#icon-bilibili', index: 0, url: 'https://www.bilibili.com' },
    '14': { name: 'ACFUN', icon: '#icon-acfun', index: 1, url: 'http://www.acfun.cn/' },
    '15': { name: '西瓜视频', icon: '#icon-xiguashipin', index: 2, url: 'https://www.ixigua.com/' },
    '16': { name: 'YOUTUBE', icon: '#icon-YouTube', index: 3, url: 'https://www.youtube.com/' },
    '17': { name: '斗鱼', icon: '#icon-dy', index: 4, url: 'https://www.douyu.com/' },
    '18': { name: '优酷', icon: '#icon-youku', index: 5, url: 'https://www.youku.com/' },
    '19': { name: '腾讯视频', icon: '#icon-txsp', index: 6, url: 'https://v.qq.com/' },
    '20': { name: '爱奇艺', icon: '#icon-aqy', index: 7, url: 'https://www.iqiyi.com/' },
    '21': { name: '网易云音乐', icon: '#icon-wangyiyun', index: 8, url: 'https://music.163.com/' },
    '22': { name: 'QQ音乐', icon: '#icon-QQMusic', index: 9, url: 'https://y.qq.com/' },
    '23': { name: '酷狗音乐', icon: '#icon-kugoulingsheng', index: 10, url: 'http://www.kugou.com/' },
    '24': { name: '搜狐视频', icon: '#icon-souhushipin', index: 11, url: 'https://tv.sohu.com/' },
    '25': { name: 'twiter', icon: '#icon-twiter', index: 0, url: 'https://twitter.com/' },
    '26': { name: 'telegram', icon: '#icon-telegram', index: 1, url: 'https://web.telegram.org/' },
    '27': { name: 'outlook', icon: '#icon-outlook', index: 2, url: 'https://outlook.live.com/' },
    '28': { name: '微博', icon: '#icon-xinlang', index: 3, url: 'https://weibo.com/' },
    '29': { name: '知乎', icon: '#icon-zhihu1', index: 4, url: 'https://www.zhihu.com/' },
    '30': { name: '豆瓣', icon: '#icon-douban', index: 5, url: 'https://www.douban.com/' },
    '31': { name: 'Gmail', icon: '#icon--gmail', index: 6, url: 'https://www.google.com/gmail' },
    '32': { name: 'QQ邮箱', icon: '#icon-QQyouxiang', index: 7, url: 'https://mail.qq.com/' },
    '33': { name: '吾爱破解', icon: '#icon-wat', index: 8, url: 'https://www.52pojie.cn/' },
    '34': { name: 'QQ空间', icon: '#icon-qqkongjian', index: 9, url: 'https://qzone.qq.com/' },
    '35': { name: '百度贴吧', icon: '#icon-tieba', index: 10, url: 'https://tieba.baidu.com/' },
    '36': { name: 'github', icon: '#icon-github', index: 10, url: 'https://github.com/explore' },
    '37': { name: '天猫', icon: '#icon-tm', index: 0, url: 'https://www.tmall.com' },
    '38': { name: '京东', icon: '#icon-ziyuan', index: 1, url: 'https://www.jd.com' },
    '39': { name: '淘宝', icon: '#icon-tb', index: 2, url: 'https://www.taobao.com/' },
    '40': { name: '美团', icon: '#icon-meituan1', index: 3, url: 'https://www.meituan.com/' },
    '41': { name: '拼多多', icon: '#icon-pinduoduo', index: 4, url: 'https://youhui.pinduoduo.com/' },
    '42': { name: '网易考拉', icon: '#icon-wykl', index: 5, url: 'https://www.kaola.com/' },
    '43': { name: '当当', icon: '#icon-dd', index: 6, url: 'http://book.dangdang.com/' },
    '44': { name: '亚马逊', icon: '#icon-ymx', index: 7, url: 'https://www.amazon.cn/' },
    '45': { name: '聚划算', icon: '#icon-juhuasuan1', index: 8, url: 'https://ju.taobao.com/' },
    '46': { name: '值得买', icon: '#icon-smzdm', index: 9, url: 'https://www.smzdm.com/' },
    '47': { name: '飞猪', icon: '#icon-fz', index: 10, url: 'https://www.fliggy.com/' },
    '48': { name: '唯品会', icon: '#icon-weipinhui', index: 11, url: 'https://www.vip.com/' },
    '49': { name: '谷歌翻译', icon: '#icon-googletranslate', index: 0, url: 'https://translate.google.cn/' },
    '50': { name: '必应翻译', icon: '#icon-biying1', index: 1, url: 'http://cn.bing.com/translator/' },
    '51': { name: '百度翻译', icon: '#icon-baidu', index: 2, url: 'https://fanyi.baidu.com' },
    '53': { name: '百度云', icon: '#icon-baiduyun1', index: 4, url: 'https://pan.baidu.com/' },
    '54': { name: '谷歌地图', icon: '#icon-gugeditu', index: 5, url: 'https://www.google.com/maps' },
    '55': { name: '百度地图', icon: '#icon-baidux', index: 6, url: 'https://map.baidu.com/' },
    '56': { name: '有道', icon: '#icon-youdao1', index: 7, url: 'https://www.youdao.com/' },
    '57': { name: 'tool', icon: '#icon-gongju1', index: 8, url: 'http://www.nicetool.net/' },
    '59': { name: '全球护照', icon: '#icon-huzhao', index: 10, url: 'https://www.passportindex.org/cn/' },
    '60': { name: '气象观测', icon: '#icon-tianqiqingkuang', index: 11, url: 'https://www.windy.com/22.534/114.112?21.956,114.109,8,m:eiIajlm' },
    '61': { name: '2048', icon: '#icon-GameController', index: 0, url: 'http://game.iyou.ink/2048/' },
    '62': { name: 'bird', icon: '#icon-itz-logo--', index: 1, url: 'http://game.iyou.ink/clumsy-bird/' },
    '63': { name: 'hextris', icon: '#icon-Help', index: 2, url: 'http://game.iyou.ink/hextris-gh-pages/' },
    '64': { name: 'mikutap', icon: '#icon-M', index: 3, url: 'http://game.iyou.ink/mikutap/' },
    '65': { name: 'pacman', icon: '#icon-pacman', index: 4, url: 'http://game.iyou.ink/pacman/' },
    '66': { name: 'pakia', icon: '#icon-bird', index: 5, url: 'http://game.iyou.ink/pappu-pakia/' },
    '67': { name: '磁力搜', icon: '#icon-magnet', index: 6, url: 'https://bt.lansou.pw/' },
    '68': { name: '豆瓣FM', icon: '#icon-dbfm', index: 7, url: 'https://douban.fm/' },
    '69': { name: '维基百科', icon: '#icon-wiki', index: 8, url: 'https://zh.wikipedia.org' },
    '70': { name: '阿里云', icon: '#icon-aly', index: 9, url: 'https://www.aliyun.com' },
};

export const MENU = {
    'news': { title: '新闻 · 资讯', content: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] },
    'media': { title: '视频 · 音乐', content: ['13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'] },
    'social': { title: '社交 · 邮箱', content: ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'] },
    'shop': { title: '购物', content: ['37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48'] },
    'tools': { title: '实用工具', content: ['49', '50', '51', '53', '54', '55', '56', '57', '59', '60'] },
    // 'game': { title: '小游戏 · 酷站', content: ['61', '62', '63', '64', '65', '66', '67', '68', '69', '70'] }
};