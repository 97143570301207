import React from 'react';
//引入UI
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
//引入子组件
import SearchEngine from './engine'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import * as Config  from '../../config';
import Favorite from './favorite';
import {jsonp} from './common';

//可以做静态样式引入  在构造函数里可以做主题切换
require('./search.css')

interface Props  {
  menu:   any;
  // socket: any;
  search: any;
  toggleMenu:          Function;
  changeSearchHistory: Function;
  hiddenSearchHistory: Function;
  queryKeywordList:    Function;
  toggleKeywordList:   Function;
  toggleFavorite:      Function;
  toggleSearchEngine:  Function;
  changeFavoriteList:  Function;
}
interface State  {
  historyState: boolean;
  keywordState: boolean;
}
export default class Search extends React.Component<Props, State> {
  constructor(props:Props){
    super(props)
    // 避免多次数据绑定 将socket待触发接口放到构造函数里 否则将多次绑定 多次触发
    // this.props.socket.on('res-chat', (res: any) => {
    //   if(res.code === 200){
    //     this.queryKeyword(res.data)
    //   }
    // });
    // this.props.socket.on('res-test', (res: any) => {
    //     this.test(res)
    // });
    this.state = {
      historyState: true,
      keywordState: true,
    }
    // this.props.socket.emit('req-test',{test:'this is test'})
    console.log('constructor: ', 'Search构造函数')
  }
  // componentDidMount() {
  //   //绑定键盘操作事件
  //   console.log('componentDidMount: ','组件渲染完成后执行')
  // }
  // componentWillUnmount() {
  //   //解除键盘操作事件 防止多次绑定
  //   console.log('componentWillUnmount: ','组件卸载后执行')
  // }
  test(data:any){
    console.log(data);
  }
  getSearchValue (){
    return (document.getElementById('iyou_search') as HTMLInputElement)
  }
  clickSearch = () => {
    this.searchOperation()
  }
  searchListClick(e: any){
    let text:string, textNode:any;
    textNode = e.currentTarget.getElementsByTagName('span')[0]
    text = textNode.innerHTML
    let iyouSearch = (document.getElementById('iyou_search') as HTMLInputElement)
    iyouSearch.value = text
    //搜索 并保存记录
    if(text){
      this.searchOperation()
      this.props.changeSearchHistory(text)
    }
  }
  hiddenSearchList = () => {
    //置空搜索列表样式状态
    let searchLi = (document.getElementById('search-li') as any)
    if(searchLi){
      searchLi.id = ''
    }
    this.props.hiddenSearchHistory(false)
    this.props.toggleKeywordList(false)
  }
  searchKeyDown(e:any) {
    let walker: any, searchList: any, searchLi: any;
    if(e.keyCode === 40 || e.keyCode === 38){//阻止光标移动
      e.preventDefault()
      searchList = (document.getElementById('search-list') as any)
      if(searchList){
        walker = (document.createTreeWalker(searchList, NodeFilter.SHOW_ELEMENT, null) as any)
      }
    }
    searchLi = (document.getElementById('search-li') as any)
    //搜索列表切换
    this.toggleSearchList(walker, searchList, searchLi, e.keyCode)
    //搜索列表还原状态
    if(Boolean(searchLi) && e.keyCode === 8){
      searchLi.id = ''
    }
    //搜索 enter
    if(e.keyCode === 13) {
      this.searchOperation()
    }
  }
  toggleSearchList(walker:any, searchList: any, searchLi: any, keyCode: number){
    if((keyCode !== 38 && keyCode !== 40) || !searchList.childNodes.length) {
      return
    }
    let activeLI:any
    if(!searchLi){
      if(keyCode === 38) activeLI = walker.lastChild()
      if(keyCode === 40) activeLI = walker.firstChild()
      activeLI.id = 'search-li'
      this.querySearchListValue(activeLI)
      return
    }
    walker.currentNode = searchLi
    if(keyCode === 38) activeLI = walker.previousSibling()
    if(keyCode === 40) activeLI = walker.nextSibling()
    searchLi.id = ''
    if(!activeLI){
      walker.currentNode = searchList
      if(keyCode === 38) activeLI = walker.lastChild()
      if(keyCode === 40) activeLI = walker.firstChild()
    }
    activeLI.id = 'search-li'
    this.querySearchListValue(activeLI)
  }
  searchOperation(){
    let iyouSearch = this.getSearchValue()
    let text = iyouSearch.value
    if(!text) return false
    this.props.changeSearchHistory(iyouSearch.value)
    //搜索
    const url = Config.SEARCH_TYPE[this.props.search.searchEngine].url
    window.open(url + text, '_blank')
  }
  querySearchListValue(activeLI:any){
    let iyouSearch = this.getSearchValue()
    let textNode = activeLI.getElementsByClassName('MuiListItemText-primary')[0]
    iyouSearch.value = textNode.innerHTML
    return textNode.innerHTML
  }
  queryKeyword(keywordArr: string[]){
      this.props.queryKeywordList(keywordArr)
  }
  sendKeyword (){
    let iyouSearch = this.getSearchValue()
    if(iyouSearch.value){
      //隐藏搜索记录
      this.props.hiddenSearchHistory(false)
      this.props.toggleKeywordList(true)
      jsonp(
        {
          url: `https://www.baidu.com/su`,
          cb: 'cb',
          data: { wd: iyouSearch.value }
        }
      ).then(
        d => this.queryKeyword((d as any).s as string[])
      )

      // this.queryKeyword(res.data)
      // this.props.socket.emit('req-chat', {keyword:iyouSearch.value})
    }else{
      this.props.hiddenSearchHistory(true)
      this.props.toggleKeywordList(false)
    }
  }
  hiddenPopUpLayer(e: any){
    if(e.currentTarget.id === 'search-position' && this.props.menu.toggle_menu === true){
        this.props.toggleMenu(false);
        this.props.toggleFavorite(false);
    }
  }
  render(){
    let keywordArr = this.props.search.historyState ? this.props.search.historyList:
                     this.props.search.keywordState ? this.props.search.keywordList:[];
    return (
      <Paper className='search-position' id = 'search-position' onClick={(e) => this.hiddenPopUpLayer(e)}>
        <div id = 'search-box' className='col-lg-7 col-md-8  col-sm-12'>
          <img alt = 'logo' className = 'logo' src = '/logo/site-logo-transparent1.png'/ >
          <Paper className='search-root'>
            {/* 搜索引擎 */}
            <SearchEngine {...this.props}/>
            {/* 输入框 */}
            <InputBase
              id="iyou_search"
              className='iyou_search'
              placeholder="Search"
              autoComplete="off"
              inputProps={{ 'aria-label': 'search' }}
              onChange = {() => this.sendKeyword() }
              onClick = {() => this.sendKeyword() }
              onBlur = {this.hiddenSearchList}
              onKeyDown = {(e) => this.searchKeyDown(e)}
            />
            {/* 搜索按钮 */}
            <IconButton  color='primary' type="button" className='iconButton' onClick = { this.clickSearch } aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Favorite {...this.props}/>
          {/* 关键词列表 */}
          <div className='search-list-box col-xs-12'>
            <List dense= {true} id = 'search-list'>
              {keywordArr.map((val:string, key:number) =>{
                return (<ListItem className = 'hvr-sweep-to-right'
                  key = {key}
                  onMouseDown = {(e)=> this.searchListClick(e)}
                  >
                    <ListItemText primary={val} />
                  </ListItem>)
              })}
            </List>
          </div>
        </div>
      </Paper>
    )
  }
}