export const Cookie = {
  get: function (name:string){
      var cookieName  = encodeURIComponent(name) + "=",
          cookieStart = document.cookie.indexOf(cookieName),
          cookieValue = null;
      if (cookieStart > -1){
          var cookieEnd = document.cookie.indexOf(";", cookieStart);
          if (cookieEnd === -1){
              cookieEnd = document.cookie.length;
          }
          cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
       }
      return cookieValue;
  },
  set: function (name:string, value:string, expires?: number | any, path?: string, domain?: string, secure ?: string) {
      var cookieText = encodeURIComponent(name) + "=" +
                       encodeURIComponent(value);
      if (expires) {
          const exp = new Date()
          const dayms   = 24*60*60*1000
          exp.setTime(exp.getTime() + expires * dayms)
          cookieText += "; expires=" + exp.toUTCString();
      }
      if (path) {
          cookieText += "; path=" + path;
      }
      if (domain) {
          cookieText += "; domain=" + domain;
      }
      if (secure) {
          cookieText += "; secure";
      }
      document.cookie = cookieText;
  },
  unset: function (name:string, path?: string, domain?: string, secure?: string){
   this.set(name, "", new Date(0), path, domain, secure);
  }
};