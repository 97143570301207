import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import Menu from '../search/menu'
import './nav.css'

interface Props {
    search:   any;
    menu:     any;
    snackbar: any;
    changeFavoriteList:  Function;
    toggleMenu:          Function;
    toggleFavorite:      Function;
    toggleSearchEngine:  Function;
    toggleSnackbar:      Function;
    toggleSnackbarText:  Function;
    toggleSnackbarColor: Function;
}
interface State {
    toggleMenu: boolean,
}

export default class BottomNav extends React.Component<Props, State>{
    constructor(props:Props){
        super(props)
        this.state = {
            toggleMenu: false,
        }
    }
    toggleMenu(e: any) {
        e.stopPropagation()
        if(e.currentTarget.id === 'toggle-menu-button'){
            this.setMenuHeight();
            this.props.toggleMenu();
            this.props.toggleFavorite(false);
        }
    }
    hiddenComponent(e:any) {
        //TODO: 点击父组件隐藏所有子组件
        if(e.target.id === 'bottom-nav'){
            this.props.toggleMenu(false);
        }
    }
    setMenuHeight() {
        let menu = document.getElementById('menu');
        if(menu){
            menu.style.height = window.innerHeight - 130 + 'px';
        }
    }
    render(){
        return(
            <div id='bottom-nav'
                onClick = {(e) => this.hiddenComponent(e)}
            >
                <Menu {...this.props} />
                <BottomNavigation
                    // value={value}
                    // onChange={(event, newValue) => {
                    //     setValue(newValue);
                    // }}
                    showLabels
                >
                    <BottomNavigationAction
                        onClick = {(e)=>{this.toggleMenu(e)}} id = 'toggle-menu-button'  label="导航" className = 'hvr-underline-from-left hvr-grow'
                        icon={
                            <svg className="icon" aria-hidden="true">
                                <use href="#icon-daohang"></use>
                            </svg>
                        }
                    />
                    <BottomNavigationAction
                        onClick = {(e)=>{this.toggleMenu(e)}} id = 'toggle-music-button' label="音乐" className = 'hvr-underline-from-center hvr-grow'
                        icon={
                            <svg className="icon" aria-hidden="true">
                                <use href="#icon-qqyinle"></use>
                            </svg>
                        }
                    />
                    <BottomNavigationAction
                        onClick = {(e)=>{this.toggleMenu(e)}} id = 'toggle-note-button' label="笔记" className = 'hvr-underline-from-center hvr-grow'
                        icon={
                            <svg className="icon" aria-hidden="true">
                                <use href="#icon-biji2"></use>
                            </svg>
                        }
                    />
                    <BottomNavigationAction
                        onClick = {(e)=>{this.toggleMenu(e)}} id = 'toggle-chat-button' label="聊天"  className = 'hvr-underline-from-right'
                        icon={
                            <svg className="icon" aria-hidden="true">
                                <use href="#icon-liaotian"></use>
                            </svg>
                        }
                    />
                    {/* <BottomNavigationAction label="设置"
                        icon={
                            <svg className="icon" aria-hidden="true">
                                <use href="#icon-engineering"></use>
                            </svg>
                        }
                    /> */}
                </BottomNavigation>
            </div>
        );
    }
}