import React    from 'react'
import Box    from '@material-ui/core/Box';
import Button   from '@material-ui/core/Button';
// import {Cookie} from '../../static/typescript/common'
import './favorite.css'
interface Props  {
    // socket: any;
    search: any;
    changeFavoriteList:  any;
}
export default class Favorite extends React.Component<Props> {
    // constructor(props:Props){
    //     super(props)
    // }TypeError: Cannot set property default of #<Object> which has only a getter
    render(){
        const favoriteList = this.props.search.favoriteList;
        return (
            <Box  id = 'favorite-list'  className='col-12'>
            {favoriteList.map((site:{name:string,icon:string,url:string,index:number}, key:number) => {
                return (
                  // <div>
                    <Button href={site.url}key={key} target='_blank' className = 'favorite-list col-3  col-xl-2'>
                      {/* <div
                        className= {this.state.addFavorite ? "icon-favorite animated rotateIn":"icon-favorite animated rotateOut"}
                        data-key = {key}
                        onClick={(e)=> this.addFavorite(e)}
                      >
                        <svg className="icon" aria-hidden="true">
                          <use href='#icon-zu'></use>
                        </svg>
                      </div> */}
                      <div className='favorite-icon'>
                        <svg className="icon" aria-hidden="true">
                          <use href={site.icon}></use>
                        </svg>
                      </div>
                      <div className="favorite-icon-title">
                        {site.name}
                      </div>
                    </Button>
                  // </div>
                  )
              })}
              </Box>
        )
    }
}