import React        from 'react';
import Button       from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import * as Config  from '../../config';

interface Props  {
  search: {searchEngine: number};
  toggleSearchEngine: any;
}
interface State  {
  engineList: boolean,
}

export default class keyworList extends React.Component<Props, State> {
  constructor(props:Props){
    super(props)
    this.state = {
        engineList: false,
    }
  }
  toggleEngineMenu = () => {
    this.setState({
        engineList: !this.state.engineList,
    })
  }
  toggleEngine = (e: any) => {
    if(e.currentTarget.dataset.engine && e.currentTarget.dataset.flag === 'true'){
      this.props.toggleSearchEngine(e.target.dataset.engine)
      this.setState({
        engineList: !this.state.engineList,
      })
    }
  }
  render(){
    return (
        <div id="search-engine-type">
          <Button
            color = 'primary'
            onClick={this.toggleEngineMenu}
          >
            <svg className="icon" aria-hidden="true">
              <use href={Config.SEARCH_TYPE[this.props.search.searchEngine].icon}></use>
            </svg>
          </Button>
          <Paper  id = 'engine-list'
            className={`first-mount-hidden animated  ${this.state.engineList ? ' fadeInLeft' : 'fadeOutLeft'}`}
            style = {this.state.engineList ? {left: '0px'}: {left: '-80px'}}
          >
            <MenuList>
            {Config.SEARCH_TYPE.map((val:{name:string, index:number}, key:number) =>{
                  return (
                    <MenuItem
                      color = 'primary'
                      key = {key}
                      className = {`engine-li ${Config.SEARCH_TYPE[this.props.search.searchEngine].index === key ? 'engine-active':''}`}
                      onClick = {(e)=> this.toggleEngine(e)}
                      data-engine = {val.index}
                      data-flag = {this.state.engineList ? 'true': 'false'}
                    >
                      {val.name}
                    </MenuItem>
                  )
              })}
              </MenuList>
          </Paper>
        </div>
    )
  }
}