interface Snackbar{
  toggle_snackbar: boolean;
  toggle_snackbar_text: string;
	toggle_snackbar_color: number;
}

const snackbarState: any = {
  toggle_snackbar: false,
  toggle_snackbar_text: '提示',
	toggle_snackbar_color: 1, //1、错误色 #D32F2F 2、警告 #FFA000 3、信息 #90CAF9 4、成功 # 43A047 5、黑 #333 6、白色 #EEE
};

const TOGGLE_SNACKBAR       = 'toggle_snackbar'
const TOGGLE_SNACKBAR_TEXT  = 'toggle_snackbar_text'
const TOGGLE_SNACKBAR_COLOR = 'toggle_snackbar_color'

export default function (state = snackbarState , action:{type:string, payload:any})  {
  switch(action.type) {
    case TOGGLE_SNACKBAR_COLOR :
    case TOGGLE_SNACKBAR_TEXT :
    case TOGGLE_SNACKBAR:
      state[action.type]       = action.payload
      return Object.assign({}, state)
    default:
      return state;
  }
}

//默认action 动作
function defaultAction(type:string, payload:boolean|number|string){
	return {
		type,
		payload
	}
}

export function toggleSnackbar(payload:boolean) {
	return defaultAction(TOGGLE_SNACKBAR, payload)
}

export function toggleSnackbarColor(payload:number) {
	return defaultAction(TOGGLE_SNACKBAR_COLOR, payload)
}

export function toggleSnackbarText(payload:string) {
	return defaultAction(TOGGLE_SNACKBAR_TEXT, payload)
}